<template>
  <div :style="heightStyleOuterDiv">
    <div :style="heightStyleInnerDiv">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offset: {
      type: Number,
      required: false,
      default: 215
    },
    tableHeightOffset: {
      type: Number,
      required: false,
      default: 50
    }
  },
  computed: {
    heightStyleOuterDiv() {
      return `height:calc(100vh - ${this.offset}px)`;
    },
    heightStyleInnerDiv() {
      return `height:calc(100vh - ${this.offset + this.tableHeightOffset}px)`;
    },
  }
}
</script>
